import { Card, Typography } from "@mui/material";
import EntryList from "./EntryList";
import * as React from "react";
import ExerciseGraph from "./ExerciseGraph";
import { getExercises } from "../apiRequests";
import { getTheme } from "../../themes";

export default function ExerciseDetails(props) {
  const { status, data, error, isFetching } = getExercises();
  if (status === "loading") {
    return "Loading";
  }
  if (status === "error") return "Error";

  let exercise = data.exerciseMap[props.exercise.id];
  if (!exercise) {
    return "Error: could not find exercise";
  }

  let theme = getTheme(exercise.category);

  return (
    <div style={{ margin: "1rem 0.2rem", textAlign: "left" }}>
      <div style={{ padding: "0 0.35rem" }}>
        <Card
          style={{
            paddingBottom: "10px",
            paddingTop: "10px",
            marginTop: "0rem",
            marginBottom: "1rem",
            backgroundColor: "#ffffff",
            borderRadius: "5px",
            border: "2px solid " + theme.border,
          }}
        >
          <span
            style={{
              fontWeight: "550",
              color: theme.text,
              marginLeft: "0.5rem",
            }}
          >
            Progression
          </span>
          <Typography
            color={"text.secondary"}
            style={{
              marginLeft: "0.45rem",
              marginTop: "0.5rem",
              marginBottom: "-0.7rem",
              fontSize: "0.8rem",
            }}
          >
            {call(() => {
              if (exercise.exerciseEntries.length === 0) return "";
              else if (exercise.exerciseName === "Bodyweight") return "Weight";
              else if (exercise.bodyWeight) return "Reps";
              else return "Estimated 1RM";
            })}
          </Typography>
          <ExerciseGraph exercise={exercise} graphTime={3} />
          {exercise.exerciseEntries.length === 0 ? (
            <div
              style={{
                paddingTop: "50px",
                paddingBottom: "50px",
                textAlign: "center",
              }}
            >
              {" "}
              <Typography color={theme.textGray}>No graph yet</Typography>{" "}
            </div>
          ) : null}
        </Card>
        <Card
          style={{
            paddingBottom: "10px",
            paddingTop: "10px",
            marginTop: "1rem",
            marginBottom: "1rem",
            backgroundColor: "#ffffff",
            borderRadius: "5px",
            border: "2px solid " + theme.border,
          }}
        >
          <span
            style={{
              fontWeight: "550",
              color: theme.text,
              marginLeft: "0.5rem",
            }}
          >
            History
          </span>
          <EntryList exercise={exercise} theme={theme} />
        </Card>
      </div>
    </div>
  );
}

function call(fun) {
  return fun();
}
