export function getTheme(category) {
  /*
  if (category === "Push") return themes[1];
  else if (category === "Pull") return themes[3];
  else if (category === "Legs") return themes[2];
   */

  return themes[0];
}

export function getFavTheme() {
  return {
    text: "#2b1e02",
    textDark: "#261b02",
    textGray: "#474747",
    backgroundLight: "#fff8e0",
    backgroundGrad2: "#fcfbf5",
    graphStroke: "#c28a13",
    graphArea: "#c28a1360",
    yellow: "#ffc400",
  };
}

export const themes = [
  {
    text: "#0e4787",
    textDark: "#09305c",
    textGray: "#474747",
    backgroundVeryLight: "#ebefff",
    backgroundLight: "#e0e7ff",
    backgroundGrad1: "#edf1ff",
    backgroundGrad2: "#f7f9ff",
    graphStroke: "#156ccfcc",
    clickableBox: "#6c94c4",
    clickableBoxSecondary: "#8868b0",
    textSecondary: "#3c2954",
    border: "#6c94c4",
    graphArea: "#156ccf40",
    backgroundArea: "#abbcf5",
    grayBackground: "#e0e0e0",
    grayOutline: "#7d7d7d",
    redBackground: "#ffd9d9",
    redOutline: "#bd7575",
    redText: "#8f2e2e",
    orangeBackground: "#fff4d1",
    orangeOutline: "#baa665",
    yellow: "#ffc400",
    yellowOutline: "#cfb708",
    green: "#51b01a",
  },
  {
    text: "#005953",
    textDark: "#002e2b",
    textGray: "#474747",
    backgroundLight: "#d8edec",
    backgroundGrad1: "#f0fcfc",
    backgroundGrad2: "#f7ffff",
    graphStroke: "#13a197",
    graphArea: "#13a19760",
  },
  {
    text: "#5e4208",
    textDark: "#2b1e02",
    textGray: "#474747",
    backgroundLight: "#f2ece1",
    backgroundGrad1: "#f7f4ed",
    backgroundGrad2: "#fffdfa",
    graphStroke: "#c28a13",
    graphArea: "#c28a1360",
  },
  {
    text: "#3c2954",
    textDark: "#1b0c2e",
    textGray: "#474747",
    backgroundLight: "#efe8fa",
    backgroundGrad1: "#f4f0fa",
    backgroundGrad2: "#f7f5fa",
    graphStroke: "#844bcc",
    graphArea: "#844bcc60",
  },
];
