import React, { useEffect, useRef, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function NavFrame(props) {
  if (!props.nav) return;

  const isRoot = props.nav.stack.length === 1;

  const content = props.nav.stack[props.nav.stack.length - 1];
  const page = content.page;
  const title = content.title;

  return (
    <div
      style={{
        position: "relative",
        boxSizing: "border-box",
        width: "100%",
        overflow: "hidden",
      }}
    >
      {!isRoot ? (
        <div>
          <div
            style={{
              height: "2rem",
              borderBottom: "solid 1px gray",
              textAlign: "center",
              paddingTop: "0.75rem",
              margin: "0 0.5rem",
            }}
          >
            <Typography sx={{ fontWeight: "550", color: "#2e2e2e" }}>
              {title}
            </Typography>
          </div>

          <div
            style={{
              position: "absolute",
              top: "0.25rem",
              left: "-0.5rem",
            }}
          >
            <Button
              size={"large"}
              variant={"text"}
              endIcon={<ArrowBackIcon />}
              onClick={() => props.nav.goBack()}
            />
          </div>
        </div>
      ) : null}

      <div>{page}</div>
    </div>
  );
}
