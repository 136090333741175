import * as React from "react";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export default function Overlay(props) {
  let title = "";
  let content = "";
  const showOverlayWithTitle = props.showOverlayWithTitle;
  if (props) title = props.title ? props.title : "";
  if (props) content = props.content ? props.content : "";
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: "5",
        backgroundColor: "rgba(0,0,0,0.7)",
        minHeight: "100vh",
      }}
    >
      <Paper
        sx={{
          position: "absolute",
          maxWidth: "500px",
          margin: "auto",
          width: "97%",
          borderRadius: "5px",
          top: "65px",
          left: "50%",
          msTransform: "translate(-50%)",
          transform: "translate(-50%)",
        }}
      >
        <div
          style={{
            height: "2rem",
            borderBottom: "solid 1px gray",
            textAlign: "left",
            paddingTop: "0.5rem",
            marginLeft: "0.65rem",
            marginRight: "0.65rem",
          }}
        >
          <div style={{ textAlign: "center", paddingTop: "0.35rem" }}>
            <Typography
              sx={{
                fontWeight: "550",
                color: "#2e2e2e",
                display: "inline",
              }}
            >
              {title}
            </Typography>
          </div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: "-20px",
            }}
          >
            <Button
              sx={{
                display: "inline",
                padding: "0.55rem 0",
              }}
              size={"large"}
              color={"error"}
              variant={"text"}
              endIcon={<CancelIcon />}
              onClick={() => showOverlayWithTitle(null)}
            />
          </div>
        </div>

        <div
          style={{
            maxHeight: "65vh",
            overflowY: "auto",
            borderRadius: "5px",
          }}
        >
          {content}
        </div>
      </Paper>
    </div>
  );
}
