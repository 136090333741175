import * as React from "react";
import { useState } from "react";
import { Typography } from "@mui/material";
import { getTheme } from "../../themes";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import EditIcon from "@mui/icons-material/Edit";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CollectionAddExercise from "./CollectionAddExercise";
import { getExercises, getUser, updateCollection } from "../apiRequests";
import ExerciseHeader from "../exercise/ExerciseHeader";
import { useQueryClient } from "react-query";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import CollectionThumbnail from "./CollectionThumbnail";

export default function Collection(props) {
  const queryClient = useQueryClient();
  if (!props.collection) return;

  const [inEditMode, setInEditMode] = useState(false);
  const [collectionEdit, setCollectionEdit] = useState(undefined);
  const [exerciseCards, setExerciseCards] = useState(undefined);
  const userData = getUser();
  const exercisesData = getExercises();

  if (exercisesData.status === "loading" || userData.status === "loading") {
    return "Loading";
  }
  if (exercisesData.status === "error" || userData.status === "error")
    return "Error";

  /*
  useEffect(() => {
    if (!inEditMode && userData.data) {
      console.log("set collection");
      setCollectionEdit(userData.data.collectionMap[props.collection.id]);
    }
  }, [collectionEdit, userData]);

   */

  let collection = userData.data.collectionMap[props.collection.id];
  let rootCollection = userData.data.rootCollection;
  let exerciseMap = exercisesData.data.exerciseMap;
  let isRootCollection = collection.id === rootCollection.id;

  let theme = getTheme();

  /*
  if (inEditMode)
    return (
      <CollectionEdit
        collection={collection}
        exercisesData={exercisesData}
        setInEditMode={setInEditMode}
        showOverlay={props.showOverlay}
      />
    );

   */

  return (
    <div style={{ margin: "0.3rem 0.45rem", textAlign: "left" }}>
      <div
        style={{
          margin: "0 0.2rem 0rem 0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ overflow: "hidden" }}>
          <Typography
            sx={{ fontSize: "1.1rem", marginTop: "1.5rem" }}
            gutterBottom
          >
            Exercises
          </Typography>
        </div>
        <div style={{ paddingTop: "1px" }}>
          {(!inEditMode && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
                flexWrap: "wrap",
                color: "#544940",
                fontSize: "0.95rem",
              }}
            >
              <div
                onClick={() => {
                  setCollectionEdit(Object.assign({}, collection));
                  setInEditMode(true);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                  flexWrap: "wrap",
                  margin: "0 15px",
                }}
              >
                <EditIcon
                  sx={{
                    height: "1rem",
                    width: "1rem",
                    paddingTop: "0px",
                    paddingRight: "0.25rem",
                    cursor: "pointer",
                  }}
                />
                <span style={{ cursor: "pointer" }}>Edit</span>
              </div>
              <div
                onClick={() => {
                  props.showOverlay(
                    <CollectionAddExercise
                      showOverlay={props.showOverlay}
                      collection={collection}
                      exerciseMap={exerciseMap}
                    />,
                    "Add"
                  );
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                  flexWrap: "wrap",
                  color: "#0e4787",
                }}
              >
                <ControlPointIcon
                  sx={{
                    height: "1.1rem",
                    width: "1.1rem",
                    paddingRight: "0.25rem",
                    paddingTop: "2px",
                    cursor: "pointer",
                  }}
                />
                <span style={{ cursor: "pointer" }}>Add</span>
              </div>
            </div>
          )) || <div style={{ minHeight: "1.2rem" }}> </div>}
        </div>
      </div>
      {(collection.exerciseIds.length > 0 && (
        <div style={{ overflow: "hidden", minHeight: "50px" }}>
          <List
            sx={{
              padding: 0,
              width: "100%",
              backgroundColor: "transparent",
            }}
          >
            {inEditMode
              ? getExercisesCards(collectionEdit)
              : getExercisesCards(collection)}
          </List>
        </div>
      )) || (
        <div style={{ textAlign: "center", padding: "2rem 0", color: "gray" }}>
          No exercises added
        </div>
      )}
      {isRootCollection && collection.collections.length > 0 && (
        <>
          <Typography
            sx={{ fontSize: "1.1rem", marginTop: "1.0rem" }}
            gutterBottom
          >
            Collections
          </Typography>
          <List
            sx={{
              padding: 0,
              width: "100%",
              backgroundColor: "transparent",
            }}
          >
            {inEditMode
              ? getCollectionCards(collectionEdit)
              : getCollectionCards(collection)}
          </List>
        </>
      )}
      {inEditMode && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "0.5rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              margin: "0 15px",
              color: theme.redText,
            }}
          >
            <CloseIcon
              onClick={() => {
                setInEditMode(false);
              }}
              sx={{
                height: "1.1rem",
                width: "1.1rem",
                paddingTop: "4px",
                paddingRight: "0.25rem",
                cursor: "pointer",
              }}
            />
            <span
              onClick={() => {
                setInEditMode(false);
              }}
              style={{ cursor: "pointer" }}
            >
              Cancel
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              color: theme.text,
            }}
          >
            <DoneIcon
              onClick={() => {
                done();
              }}
              sx={{
                height: "1.1rem",
                width: "1.1rem",
                paddingRight: "0.25rem",
                paddingTop: "4px",
                cursor: "pointer",
              }}
            />
            <span
              onClick={() => {
                done();
              }}
              style={{ cursor: "pointer", fontSize: "1rem" }}
            >
              Save
            </span>
          </div>
        </div>
      )}
    </div>
  );

  function getExercisesCards(collection) {
    if (!collection) return;
    let ret = [];
    collection.exerciseIds.forEach((exerciseId, i) => {
      let exercise = exerciseMap[exerciseId.exerciseId];
      if (!exercise) return;
      ret.push(
        <ListItem
          key={exerciseId.exerciseId}
          sx={{
            padding: 0,
            margin: 0,
          }}
        >
          <ExerciseHeader
            sx={{ width: "100%" }}
            exercise={exercise}
            showOverlay={props.showOverlay}
            withDetails={false}
            nav={props.nav}
            inCollection={collection.id}
            inEditMode={inEditMode}
            moveUp={() => exerciseMoveUp(i)}
            moveDown={() => exerciseMoveDown(i)}
            delete={() => exerciseDelete(i)}
          />
        </ListItem>
      );
    });
    return ret;
  }

  function exerciseMoveUp(myPos) {
    if (myPos > 0 && myPos < collectionEdit.exerciseIds.length) {
      let newList = collectionEdit.exerciseIds.filter((element) => {
        return element !== undefined && element !== null;
      });

      [newList[myPos - 1], newList[myPos]] = [
        newList[myPos],
        newList[myPos - 1],
      ];
      collectionEdit.exerciseIds = newList;
      setCollectionEdit(Object.assign({}, collectionEdit));
    }
  }

  function exerciseMoveDown(myPos) {
    if (myPos >= 0 && myPos < collectionEdit.exerciseIds.length - 1) {
      let newList = collectionEdit.exerciseIds.filter((element) => {
        return element !== undefined && element !== null;
      });

      [newList[myPos + 1], newList[myPos]] = [
        newList[myPos],
        newList[myPos + 1],
      ];
      collectionEdit.exerciseIds = newList;
      setCollectionEdit(Object.assign({}, collectionEdit));
    }
  }

  function exerciseDelete(myPos) {
    let newList = collectionEdit.exerciseIds.filter((element) => {
      return element !== undefined && element !== null;
    });
    newList.splice(myPos, 1);
    collectionEdit.exerciseIds = newList;
    setCollectionEdit(Object.assign({}, collectionEdit));
  }

  function collectionMoveUp(myPos) {
    if (myPos > 0 && myPos < collectionEdit.collections.length) {
      let newList = collectionEdit.collections.filter((element) => {
        return element !== undefined && element !== null;
      });

      [newList[myPos - 1], newList[myPos]] = [
        newList[myPos],
        newList[myPos - 1],
      ];
      collectionEdit.collections = newList;
      setCollectionEdit(Object.assign({}, collectionEdit));
    }
  }

  function collectionMoveDown(myPos) {
    if (myPos >= 0 && myPos < collectionEdit.collections.length - 1) {
      let newList = collectionEdit.collections.filter((element) => {
        return element !== undefined && element !== null;
      });

      [newList[myPos + 1], newList[myPos]] = [
        newList[myPos],
        newList[myPos + 1],
      ];
      collectionEdit.collections = newList;
      setCollectionEdit(Object.assign({}, collectionEdit));
    }
  }

  function collectionDelete(myPos) {
    let newList = collectionEdit.collections.filter((element) => {
      return element !== undefined && element !== null;
    });
    newList.splice(myPos, 1);
    collectionEdit.collections = newList;
    setCollectionEdit(Object.assign({}, collectionEdit));
  }

  function getCollectionCards(collection) {
    let ret = [];
    if (!isRootCollection) return;

    collection.collections.forEach((subCollection, i) => {
      ret.push(
        <ListItem
          key={subCollection.name}
          sx={{
            padding: 0,
            margin: 0,
          }}
        >
          <CollectionThumbnail
            collection={subCollection}
            showOverlay={props.showOverlay}
            nav={props.nav}
            inEditMode={inEditMode}
            moveUp={() => collectionMoveUp(i)}
            moveDown={() => collectionMoveDown(i)}
            delete={() => collectionDelete(i)}
          />
        </ListItem>
      );
    });

    return ret;
  }

  function done() {
    let updatedCollection = applyChangeToRootCollection(
      rootCollection,
      collectionEdit.id,
      collectionEdit
    );
    updateCollection(updatedCollection, () => {
      queryClient.invalidateQueries("user");
      setInEditMode(false);
    });
  }

  function applyChangeToRootCollection(
    rootCollection,
    collectionId,
    changedCollection
  ) {
    if (rootCollection.id === collectionId)
      return withCorrectPositions(changedCollection);
    else {
      rootCollection.collections.forEach((collection, i) => {
        if (collection.id === collectionId)
          rootCollection.collections[i] =
            withCorrectPositions(changedCollection);
        return rootCollection;
      });
    }
    return rootCollection;
  }

  function withCorrectPositions(collection) {
    collection.exerciseIds.forEach((exerciseId, index) => {
      exerciseId.pos = index;
    });
    collection.collections.forEach((subCollection, index) => {
      subCollection.pos = index;
    });
    return collection;
  }
}
