import { useQuery } from "react-query";
import axios from "axios";

let temp_host = "http://35.217.25.119:8080";
//export let host = "http://localhost:8080";
export let userId = 0;

let public_salt = "gym_trail_public_salt";

console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV === "development") {
  temp_host = "http://localhost:8080";
  temp_host = "https://gym-trail.com";
} else {
  temp_host = window.location.origin;
}
export let host = temp_host;

export let config = {
  headers: {
    Authorization: "",
  },
};

let isMobile = false;

function handleForbidden(answer) {
  if (answer.response.status === 403 || answer.response.status === 401) {
    localStorage.removeItem("jwt");
    window.location.reload();
  }
}

export function getUser() {
  let ret = useQuery(["user"], async () => {
    const { data } = await axios
      .get(host + "/user", {
        withCredentials: true,
        headers: config.headers,
      })
      .catch((e) => {
        handleForbidden(e);
        console.log(e);
      });
    return data;
  });
  let data = ret.data;
  if (data) {
    data.selectedProgram =
      data.selectedProgram >= data.programs.length ? 0 : data.selectedProgram;

    let programMap = {};
    let collectionMap = {};

    //todo: make sure this works
    function addSubcollectionsToMap(collection) {
      collectionMap[collection.id] = collection;
      if (!collection.collections) return;
      for (let subCollection of collection.collections) {
        addSubcollectionsToMap(subCollection);
      }
    }
    addSubcollectionsToMap(data.rootCollection);
    data.rootCollection.collections = data.rootCollection.collections.sort(
      (a, b) => a.pos - b.pos
    );

    data.programMap = programMap;
    data.collectionMap = collectionMap;

    ret.data = data;
  }

  return ret;
}

export function getExercises() {
  let res = useQuery(["exercises"], async () => {
    const { data } = await axios
      .get(host + "/exercises", {
        withCredentials: true,
        headers: config.headers,
      })
      .catch((e) => {
        handleForbidden(e);
        console.log(e);
      });
    return data;
  });
  if (res.data) {
    let newData = { exercises: [], exerciseMap: {}, exerciseNameMap: {} };

    let data = res.data;
    for (let i = 0; i < data.length; i++) {
      let exerciseEntries = data[i].exerciseEntries;
      data[i].exerciseEntries = exerciseEntries.sort((a, b) => a.sse - b.sse);
    }

    let exerciseMap = {};
    let exerciseNameMap = {};
    let favorites = [];
    for (let exercise of data) {
      exerciseMap[exercise.id + ""] = exercise;
      exerciseNameMap[exercise.exerciseName] = exercise;

      if (exercise.priority > 0) {
        favorites.push(exercise);
      }
    }
    newData.exercises = data;
    newData.exerciseMap = exerciseMap;
    newData.exerciseNameMap = exerciseNameMap;
    newData.favorites = favorites;

    res.data = newData;
  }

  return res;
}

export function addExercise(exercise, callback) {
  axios
    .post(
      host + "/exercise",
      {
        userId: userId,
        exerciseName: exercise.exerciseName,
        bodyWeight: exercise.bodyWeight,
        category: exercise.category,
        code: exercise.code,
      },
      { maxRedirects: 0, headers: config.headers }
    )
    .then(function (response) {
      callback(response.data);
      console.log(response);
    })
    .catch(function (error) {
      callback();
      console.log(error);
    });
}

export function addProgram(program, callback) {
  axios
    .post(host + "/program", program, {
      maxRedirects: 0,
      headers: config.headers,
    })
    .then(function (response) {
      callback();
      console.log(response);
    })
    .catch(function (error) {
      callback();
      console.log(error);
    });
}

export function addCollectionToProgram(programId, collection, callback) {
  axios
    .post(host + "/program/" + programId + "/collection", collection, {
      maxRedirects: 0,
      headers: config.headers,
    })
    .then(function (response) {
      callback();
      console.log(response);
    })
    .catch(function (error) {
      callback();
      console.log(error);
    });
}

export function updateProgram(program, callback) {
  axios
    .put(host + "/program", program, {
      maxRedirects: 0,
      headers: config.headers,
    })
    .then(function (response) {
      callback(response);
      console.log(response);
    })
    .catch(function (error) {
      callback();
      console.log(error);
    });
}

export function uploadExercises(exercises, callback) {
  axios
    .post(host + "/exercise/upload", exercises, {
      maxRedirects: 0,
      headers: config.headers,
    })
    .then(function (response) {
      callback();
      console.log(response);
    })
    .catch(function (error) {
      callback();
      console.log(error);
    });
}

export function addExerciseEntry(entry, exerciseId, callback) {
  axios
    .post(
      host + "/exercise/" + exerciseId,
      {
        userId: userId,
        reps: entry.reps,
        weight: entry.weight,
        sse:
          entry.sse === -1
            ? Math.round(new Date().getTime() / 1000)
            : entry.sse,
        fromCollection: entry.fromCollection,
      },
      { headers: config.headers }
    )
    .then(function (response) {
      callback();
      console.log(response);
    })
    .catch(function (error) {
      callback();
      console.log(error);
    });
}

export function updateExercise(exercise, exerciseId, callback) {
  axios
    .put(host + "/exercise/" + exerciseId, exercise, {
      headers: config.headers,
    })
    .then(function (response) {
      callback();
      console.log(response);
    })
    .catch(function (error) {
      callback();
      console.log(error);
    });
}

export function deleteExerciseEntry(exerciseId, entryId, callback) {
  axios
    .delete(host + "/exercise/" + exerciseId + "/entry/" + entryId, {
      headers: config.headers,
    })
    .then(function (response) {
      callback();
      console.log(response);
    })
    .catch(function (error) {
      callback();
      console.log(error);
    });
}

export function updateCollection(collection, callback) {
  axios
    .put(host + "/update-root-collection", collection, {
      headers: config.headers,
    })
    .then(function (response) {
      callback();
      console.log(response);
    })
    .catch(function (error) {
      callback();
      console.log(error);
    });
}

export function updateUser(user, callback) {
  axios
    .put(host + "/user", user, {
      headers: config.headers,
    })
    .then(function (response) {
      callback();
      console.log(response);
    })
    .catch(function (error) {
      callback();
      console.log(error);
    });
}
