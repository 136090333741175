import { useQueryClient } from "react-query";
import { useState } from "react";
import * as React from "react";
import dayjs from "dayjs";
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { addExerciseEntry } from "../apiRequests";

export default function AddEntryPage(props) {
  const queryClient = useQueryClient();

  let lastEntry = props.lastEntry;

  const [entryReps, setEntryReps] = useState();
  const [entryWeight, setEntryWeight] = useState();

  const [value, setValue] = React.useState(dayjs());
  const [useOtherDate, setUseOtherDate] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (newValue) => {
    setValue(removeTime(newValue));
    console.log(value.unix());
    console.log(value);
  };

  function filter(str) {
    if (!str) return "";
    str = str.replaceAll(",", ".");
    str = str.replace(/[^0-9.]/g, "");
    return str;
  }

  return (
    <div
      style={{
        marginTop: "0rem",
        border: "none",
        margin: "1rem",
        textAlign: "center",
      }}
    >
      <br />
      {!props.exercise.bodyWeight ? (
        <div>
          <Typography sx={{ fontSize: 15 }} gutterBottom>
            Weight:
          </Typography>
          <TextField
            sx={{ width: "80%" }}
            name="weight"
            label="Weight"
            variant="outlined"
            inputProps={{ inputMode: "decimal" }}
            value={entryWeight}
            onChange={(event) => setEntryWeight(filter(event.target.value))}
          />
        </div>
      ) : (
        ""
      )}

      {props.exercise.exerciseName !== "Bodyweight" ? (
        <div>
          <Typography sx={{ fontSize: 15, margin: 0 }} gutterBottom>
            Reps:
          </Typography>
          <TextField
            sx={{ width: "80%" }}
            name="reps"
            label="Reps"
            variant="outlined"
            inputProps={{ inputMode: "decimal" }}
            value={entryReps}
            onChange={(event) => setEntryReps(filter(event.target.value))}
          />
        </div>
      ) : (
        ""
      )}

      <br />
      <br />
      <FormControlLabel
        sx={{ width: "79%" }}
        control={
          <Checkbox
            checked={useOtherDate}
            onChange={(e) => setUseOtherDate(e.target.checked)}
          />
        }
        label="Select past date"
      />
      {useOtherDate ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            sx={{ width: "80%" }}
            label="Date mobile"
            inputFormat="MM/DD/YYYY"
            value={value}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      ) : (
        ""
      )}
      <div style={{ textAlign: "center", marginTop: "1rem" }}>
        <LoadingButton
          loading={isLoading}
          disabled={call(() => {
            if (
              props.exercise.exerciseName === "Bodyweight" &&
              !isNaN(Number(entryWeight)) &&
              entryWeight !== ""
            )
              return false;
            if (!props.exercise.bodyWeight) {
              let weight = Number(entryWeight);
              if (isNaN(weight) || entryWeight === "") return true;
            }
            if (isNaN(Number(entryReps)) || entryReps === "") return true;
            return false;
          })}
          sx={{ borderRadius: "2px" }}
          variant="outlined"
          onClick={() => {
            setIsLoading(true);
            let weight = props.exercise.bodyWeight ? 0 : Number(entryWeight);
            let reps = Number(entryReps);
            let entry = {
              reps: reps,
              weight: weight,
              fromCollection: props.inCollection,
            };
            if (useOtherDate) entry.sse = value.unix();
            else entry.sse = -1;
            addExerciseEntry(entry, props.exercise.id, () => {
              queryClient
                .invalidateQueries("exercises")
                .then(() => props.showOverlay(null, ""));
            });
          }}
        >
          {props.exercise.exerciseName !== "Bodyweight"
            ? "Add set"
            : "Set weight"}
        </LoadingButton>
      </div>
    </div>
  );

  function removeTime(date) {
    date = date.millisecond(0);
    date = date.second(0);
    date = date.minute(0);
    return date.hour(0);
  }

  function call(fun) {
    return fun();
  }
}
