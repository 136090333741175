import { useQueryClient } from "react-query";
import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import { sha256Lib } from "../../utils/crypto";
import { host } from "../apiRequests";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <div>
      <br />
      <Typography variant="h6" component="div">
        Login with existing user
      </Typography>
      <br />

      <Typography sx={{ fontSize: 15, margin: 0 }} gutterBottom>
        Email:
      </Typography>
      <TextField
        sx={{ width: "80%" }}
        type="text"
        name="email"
        label="email"
        variant="outlined"
        onChange={(event) => {
          setEmail(event.target.value);
        }}
      />
      <br />
      <br />

      <Typography sx={{ fontSize: 15, margin: 0 }} gutterBottom>
        Password:
      </Typography>
      <TextField
        sx={{ width: "80%" }}
        type="password"
        name="password"
        label="password"
        variant="outlined"
        onChange={(event) => {
          setPassword(event.target.value);
        }}
      />
      <br />
      <br />

      <div style={{ textAlign: "center", marginTop: "1rem" }}>
        <Button
          disabled={!(password.length > 0 && email.length > 0)}
          sx={{ borderRadius: "2px" }}
          variant="contained"
          onClick={async () => {
            let hashedPassword = sha256Lib(password);
            let user = { username: email, password: hashedPassword };
            console.log(user);
            axios
              .post(host + "/user/authenticate", user)
              .then(function (response) {
                console.log(response);
                props.setToken(response.data.jwt);
                localStorage.setItem("jwt", response.data.jwt);
                props.showOverlay(null);
                props.nav.clear();
              })
              .catch(function (error) {
                console.log(error);
                setErrorMessage(
                  "could not log in, make sure email and password is correct"
                );
              });
          }}
        >
          Login
        </Button>
      </div>
      <br />
      <p style={{ color: "red" }}> {errorMessage} </p>

      <br />
    </div>
  );
}
