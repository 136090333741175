import { Button, Grid, ListItem, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import { useQueryClient } from "react-query";
import { useState } from "react";
import { deleteExerciseEntry } from "../apiRequests";

export default function EntryList(props) {
  const queryClient = useQueryClient();
  const [numSets, setNumSets] = useState(5);

  let exercise = props.exercise;

  if (exercise.exerciseEntries.length > 0) {
    exercise.exerciseEntries = exercise.exerciseEntries.sort(
      (a, b) => b.sse - a.sse
    );
  }

  let entries = [];
  for (let i = 0; i < numSets; i++) {
    if (i >= exercise.exerciseEntries.length) break;
    let entry = exercise.exerciseEntries[i];
    entries.push(
      <div
        style={{
          borderBottom: "1px solid #cfcfcf",
          margin: "0.5rem 1rem 0 1rem",
          fontSize: "0.85rem",
        }}
      >
        <Grid container sx={{ marginBottom: "0.3rem" }}>
          <Grid item xs={5}>
            {new Date(entry.sse * 1000).toLocaleDateString("en-GB", {
              year: "2-digit",
              month: "short",
              day: "numeric",
            })}
          </Grid>
          <Grid item xs={5}>
            {exercise.bodyWeight ? "" : "Weight: " + entry.weight + ", "}{" "}
            {exercise.bodyWeight ? "" : <br />}{" "}
            {exercise.exerciseName !== "Bodyweight"
              ? "Reps: " + entry.reps
              : ""}
          </Grid>
          <Grid item xs={1}>
            <Button
              sx={{ alignContent: "right" }}
              variant="text"
              onClick={() => {
                let yes = window.confirm("Click ok to delete this entry");
                if (yes)
                  deleteExerciseEntry(exercise.id, entry.entryId, () => {
                    queryClient.invalidateQueries("exercises");
                  });
              }}
              endIcon={<DeleteIcon />}
              color={"error"}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div>
      {exercise.exerciseEntries.length === 0 ? (
        <div
          style={{
            paddingTop: "40px",
            paddingBottom: "40px",
            textAlign: "center",
          }}
        >
          <Typography color={props.theme.textGray}>No entries yet</Typography>{" "}
        </div>
      ) : (
        <div>
          {entries}
          {numSets < exercise.exerciseEntries.length ? (
            <div style={{ textAlign: "center" }}>
              <Button
                size="small"
                sx={{
                  borderRadius: "20px",
                  padding: "1rem",
                  textAlign: "center",
                }}
                variant="text"
                onClick={() => {
                  setNumSets(numSets + 5);
                }}
              >
                <Typography
                  fontSize="0.8rem"
                  sx={{ textDecoration: "underline" }}
                  component="div"
                  color={"text.secondary"}
                >
                  show more entries
                </Typography>
              </Button>
            </div>
          ) : (
            ""
          )}{" "}
        </div>
      )}
    </div>
  );
}
