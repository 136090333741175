import * as React from "react";
import Paper from "@mui/material/Paper";
import { IconButton, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { getTheme } from "../../themes";

export default function NavBar(props) {
  const isMobile = props.isMobile;
  const nav = props.nav;
  const setPageNum = props.setPageNum;
  const pageNum = props.pageNum;
  const getPage = props.getPage;

  let theme = getTheme(0);

  return (
    <Paper
      sx={{
        paddingTop: "20px",
        marginTop: "-20px",
        background: theme.text,
        width: "100%",
        borderRadius: 0,
        boxShadow: 0,
        borderBottom: "solid 1px #00000010",
        position: "fixed",
        top: isMobile ? "auto" : 0,
        bottom: !isMobile ? "auto" : 0,
        left: 0,
        right: 0,
        zIndex: "4",
      }}
      elevation={3}
    >
      <div
        style={{
          margin: "auto",
          maxWidth: "600px",
          display: "flex",
          textAlign: "left",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            padding: "0.9rem 0.5rem",
            fontSize: "1.1rem",
            cursor: "pointer",
            color: pageNum === 0 ? "#fff" : "#ffffff80",
          }}
          onClick={() => {
            setPageNum(0);
            nav.setRoot(getPage(0));
          }}
        >
          Gymtrail
        </Typography>
        <IconButton
          size="small"
          onClick={() => {
            setPageNum(1);
            nav.setRoot(getPage(1));
          }}
          sx={{
            padding: "0 0.5rem",
            color: pageNum === 1 ? "#fff" : "#ffffff80",
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </div>
    </Paper>
  );
}
