import React, { PureComponent } from "react";
import {
  Line,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Area,
} from "recharts";
import { getTheme } from "../../themes";

let data = [];

for (let i = 0; i < 30; i++) {
  data.push({ name: i, uv: 0 });
}

let maxDays = 1095 + 20;

let GRAPH_POINTS = 140;

function generateData(exerciseEntries) {
  for (let i = 0; i < maxDays; i++) {
    exerciseEntries.push({
      name: "",
      val: undefined,
      allValues: [],
      pos: i,
    });
  }
  return;
}

export default function ExerciseGraph(props) {
  if (!props.exercise) return;
  if (!props.exercise.exerciseEntries) return;
  if (props.exercise.exerciseEntries.length === 0) return;

  let theme = getTheme(props.exercise.category);
  let exerciseData = [];
  let exerciseData2 = {};
  //generateData(exerciseData);

  props.exercise.exerciseEntries = props.exercise.exerciseEntries.sort(
    (a, b) => a.sse - b.sse
  );

  let diffInTime =
    new Date().getTime() / 1000 - props.exercise.exerciseEntries[0].sse;
  let diffInDays = Math.ceil(diffInTime / (3600 * 24));
  //console.log(props.exercise.exerciseName + ": " + diffInDays);

  maxDays = diffInDays;
  if (maxDays < GRAPH_POINTS) maxDays = GRAPH_POINTS;

  let days = Math.floor(props.graphTime);
  days = 99999999;
  if (days >= maxDays) days = maxDays - 1;

  let d2 = new Date();
  //d2.setHours(0, 0, 0, 0);
  let d2_sse = d2.getTime() / 1000;
  let offset_s = d2.getTimezoneOffset() * 60.0;

  let dss = (d2_sse - offset_s) / 24.0 / 60.0 / 60.0;
  let dss_floor = Math.floor(dss);

  let first_val_index = 0;

  for (let i = 0; i < maxDays; i++) {
    let arrPos = maxDays - i - 1;

    exerciseData2[dss_floor - i] = {
      name: "",
      val: undefined,
      allValues: [],
      pos: arrPos,
      sse: (dss_floor - i) * 60 * 60 * 24,
    };
  }

  for (let entry of props.exercise.exerciseEntries) {
    //console.log(entry.sse / 24 / 60 / 60);

    let time_key = (entry.sse - offset_s) / 24.0 / 60.0 / 60.0;
    let time_key_floor = Math.floor(time_key);
    if (!exerciseData2[time_key_floor]) {
      continue;
    }
    if (props.exercise.bodyWeight) {
      exerciseData2[time_key_floor].allValues.push(entry.reps);
    } else {
      if (props.exercise.exerciseName === "Bodyweight") {
        exerciseData2[time_key_floor].allValues.push(entry.weight);
        continue;
      }
      exerciseData2[time_key_floor].allValues.push(
        entry.weight * (1 + entry.reps / 30)
      );
    }
  }
  //console.log(exerciseData2);
  exerciseData = Object.values(exerciseData2); //.reverse();
  exerciseData = applySmoothing(GRAPH_POINTS, exerciseData);

  function applySmoothing(wantedLength, data) {
    let newData = Array(wantedLength);
    for (let i = 0; i < data.length; i++) {
      let pos = Math.round((i / data.length) * wantedLength);
      if (!newData[pos]) {
        newData[pos] = data[i];
        newData[pos].pos = pos;
      } else {
        newData[pos].allValues = newData[pos].allValues.concat(
          data[i].allValues
        );
      }
    }
    return newData;
  }

  /*
  if (days > 110) {
    let smoothingRatio = days > 360 ? 10 : 7;
    let counter = 0;
    let state = {};
    for (let i = maxDays - 1; i > 0; i--) {
      if (counter === 0) counter = smoothingRatio;
      if (counter === smoothingRatio) {
        state = exerciseData[i];
      } else {
        state.allValues = state.allValues.concat(exerciseData[i].allValues);
        exerciseData[i].allValues = [];
      }

      counter--;
    }
  }
   */

  let max = -1;
  let min = -1;
  for (let point of exerciseData) {
    if (point.allValues.length === 0) continue;
    // todo: probably also check if first_val_index has a real value
    if (exerciseData[first_val_index].allValues.length === 0)
      first_val_index = point.pos;
    let sum = point.allValues.reduce((partialSum, a) => partialSum + a, 0);
    point.val = sum / point.allValues.length;
    point.val = Math.round(point.val * 10) / 10;
    //if (point.val > max || max === -1) max = point.val;
    //if (point.val < min || min === -1) min = point.val;
  }

  exerciseData[0].val = exerciseData[first_val_index].val;

  for (let i = 0; i < exerciseData.length; i++) {
    let point = exerciseData[exerciseData.length - 1 - i];
    if (point.allValues.length === 0) continue;
    if (point.val > max || max === -1) max = point.val;
    if (point.val < min || min === -1) min = point.val;
    if (i > days) break;
  }

  exerciseData[exerciseData.length - 1].isFinal = true;

  let padding = (max - min) * 0.1;
  if (padding < 1) padding = 1;

  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;
      let day = new Date(exerciseData[payload.value].sse * 1000);
      //console.log(day + ", " + payload.value);
      let x_trans = -25;
      if (exerciseData[payload.value].isFinal) x_trans = -43;
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={x_trans} y={7} dy={16} fontSize={13}>
            {day.toLocaleDateString("en-GB", {
              year: "2-digit",
              month: "short",
              day: "numeric",
            })}
          </text>
        </g>
      );
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer width="100%" height={150}>
        <AreaChart
          data={exerciseData}
          margin={{
            top: 20,
            right: 27,
            left: -27,
            bottom: 5,
          }}
        >
          <XAxis
            dataKey="pos"
            type="number"
            allowDataOverflow
            domain={[0, "dataMax"]}
            padding={{
              right: 7,
            }}
            tick={<CustomizedAxisTick />}
            ticks={[
              0,
              //(dataPointsLen - 1) / 2,
              //maxDays - 90,
              GRAPH_POINTS - 1,
            ]}
          />
          <YAxis
            domain={[
              (dataMin) => Math.round(min - padding),
              (dataMax) => Math.round(max + padding),
            ]}
          />
          <ReferenceLine
            y={max}
            label={{
              position: "top",
              value: "" + max,
              fill: "grey",
              fontSize: 14,
            }}
            stroke="grey"
            strokeDasharray="5 5"
          />
          <Area
            strokeWidth={2.4}
            connectNulls
            type={days < 110 ? "linear" : "linear"}
            dataKey="val"
            isAnimationActive={false}
            stroke={theme.graphStroke}
            fill={theme.graphArea}
            dot={{ strokeWidth: 0, r: 0, strokeOpacity: 0 }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
