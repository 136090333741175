import * as React from "react";
import { Button, Grid, Typography } from "@mui/material";
import Login from "./loginRegister/Login";
import Register from "./loginRegister/Register";
import preview_1 from "../1.png";
import preview_2 from "../2.png";
import preview_3 from "../3.png";

export default function LandingPage(props) {
  const showOverlay = props.showOverlay;
  const showOverlayWithTitle = props.showOverlayWithTitle;
  const nav = props.nav;
  const setToken = props.setToken;

  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          maxWidth: "500px",
          margin: "2rem auto",
          padding: "1rem",
          textAlign: "center",
        }}
      >
        <Typography fontSize="1.6rem" component="div" color={"text.primary"}>
          Gymtrail
        </Typography>
        <p>With Gymtrail, tracking your gym exercises has never been easier!</p>
        <p>
          Add your favorite exercises, track your progress with detailed graphs,
          and stay motivated as you work towards your fitness goals.
        </p>
        <br />

        <Grid
          container
          sx={{ textAlign: "center", width: "70%", margin: "auto" }}
        >
          <Grid item xs={6}>
            <Button
              sx={{ width: "80%" }}
              variant="contained"
              onClick={() => {
                showOverlayWithTitle(
                  <Login
                    setToken={setToken}
                    showOverlay={showOverlayWithTitle}
                    nav={nav}
                  />,
                  "Login"
                );
              }}
            >
              Login
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              sx={{ width: "80%" }}
              onClick={() => {
                showOverlayWithTitle(
                  <Register
                    setToken={setToken}
                    showOverlay={showOverlayWithTitle}
                    nav={nav}
                  />,
                  "Register"
                );
              }}
            >
              Register
            </Button>
          </Grid>
        </Grid>
        {showOverlay ? showOverlay : ""}
      </div>
      <div
        style={{
          maxWidth: "1050px",
          margin: "auto",
          marginTop: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            minWidth: "0",
            overflowX: "auto",
          }}
        >
          <img src={preview_1} width={"350"} />
          <img src={preview_2} width={"350"} />
          <img src={preview_3} width={"350"} />
        </div>
      </div>
    </>
  );
}
