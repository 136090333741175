import * as React from "react";
import { getExercises, getUser } from "./apiRequests";
import Collection from "./collection/Collection";
import { Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CollectionThumbnail from "./collection/CollectionThumbnail";

export default function MainPage(props) {
  const exercisesData = getExercises();
  const userData = getUser();
  if (exercisesData.status === "loading" || userData.status === "loading") {
    return "Loading";
  }
  if (exercisesData.status === "error" || userData.status === "error")
    return "Error";

  let rootCollection = userData.data.rootCollection;

  return (
    <>
      <Collection
        showOverlay={props.showOverlay}
        nav={props.nav}
        collection={rootCollection}
      />
    </>
  );
}
