import * as React from "react";
import { Card, Typography } from "@mui/material";
import { getTheme } from "../../themes";
import Collection from "./Collection";
import ClassIcon from "@mui/icons-material/Class";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
export default function CollectionThumbnail(props) {
  if (!props.collection) return;

  let collection = props.collection;
  let theme = getTheme();

  return (
    <Card
      onClick={(e) =>
        props.inEditMode ? e.preventDefault() : goToCollection()
      }
      sx={{
        cursor: "pointer",
        paddingBottom: 0,
        textAlign: "left",
        padding: "0rem 0.6rem",
        margin: "0.22rem auto",
        width: "97%",
        border: "2px solid " + theme.clickableBoxSecondary,
        borderRadius: "13px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            padding: props.inEditMode ? "0.5rem 0" : "1rem 0",
          }}
        >
          <div
            style={{
              fontWeight: "550",
              color: theme.textSecondary,
              fontSize: "1rem",
            }}
          >
            <ClassIcon sx={{ marginBottom: "-2px" }} fontSize="1rem" />{" "}
            {collection.name}
          </div>
          {props.inEditMode && (
            <div
              onClick={props.delete}
              style={{
                color: theme.redText,
                fontSize: "0.95rem",
                cursor: "pointer",
                textDecoration: "underline",
                marginTop: "0.3rem",
              }}
            >
              Remove
            </div>
          )}
        </div>
        {!props.inEditMode && (
          <Typography
            fontSize={"0.85rem"}
            component="div"
            color={theme.textGray}
            sx={{ padding: "1rem 0" }}
          >
            <span style={{ color: theme.textGray }}>
              {collection.exerciseIds.length} exercises
            </span>
          </Typography>
        )}
        {props.inEditMode && (
          <div
            style={{
              color: theme.text,
            }}
          >
            <div
              style={{
                marginTop: "0px",
                marginBottom: "8px",
                cursor: "pointer",
              }}
            >
              <KeyboardArrowUpIcon onClick={props.moveUp} />
            </div>
            <div
              style={{
                cursor: "pointer",
                marginBottom: "-2px",
              }}
            >
              <KeyboardArrowDownIcon onClick={props.moveDown} />
            </div>
          </div>
        )}
      </div>
    </Card>
  );

  function goToCollection() {
    props.nav.goTo(
      <Collection
        programId={props.programId}
        collection={collection}
        nav={props.nav}
        exerciseMap={props.exerciseMap}
        showOverlay={props.showOverlay}
        isSubCollection={true}
      />,
      <>
        <ClassIcon sx={{ marginBottom: "-2px" }} fontSize="1rem" />{" "}
        {collection.name}
      </>
    );
  }
}
