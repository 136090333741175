import * as React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  TextField,
  Typography,
} from "@mui/material";
import {
  addExercise,
  addExerciseEntry,
  getUser,
  updateCollection,
} from "../apiRequests";
import { getTheme, themes } from "../../themes";
import { useQueryClient } from "react-query";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

export default function (props) {
  const queryClient = useQueryClient();
  const [inputValue, setInputValue] = React.useState("");
  const [bodyWeight, setBodyWeight] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [addType, setAddType] = React.useState("exercise");

  const [collectionName, setCollectionName] = useState("");

  const userData = getUser();
  if (userData.status === "loading") {
    return "Loading";
  }
  if (userData.status === "error") return "Error";

  let rootCollection = userData.data.rootCollection;

  let theme = getTheme();

  let exerciseIdNameMap = {};
  let nameToExerciseMap = {};
  for (const [key, value] of Object.entries(props.exerciseMap)) {
    exerciseIdNameMap[key] = value;
    exerciseIdNameMap[key].label = value.exerciseName;
    nameToExerciseMap[value.exerciseName] = value;
  }
  for (const exerciseInCollection of props.collection.exerciseIds) {
    exerciseIdNameMap[exerciseInCollection.exerciseId] = null;
    delete exerciseIdNameMap[exerciseInCollection.exerciseId];
  }

  let selectList = [];
  Object.entries(exerciseIdNameMap).forEach(([key, exercise]) => {
    if (exercise) selectList.push(exercise);
  });

  let exerciseExists = !!nameToExerciseMap[inputValue];
  let exerciseId = exerciseExists ? nameToExerciseMap[inputValue].id : -1;
  let inCollection =
    nameToExerciseMap[inputValue] && !exerciseIdNameMap[exerciseId];

  const isRootCollection = props.collection.id === rootCollection.id;

  return (
    <div
      style={{
        padding: "0.3rem 0.5rem",
        minHeight: "10rem",
        textAlign: "left",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        {isRootCollection && (
          <Button
            onClick={() => setAddType("collection")}
            sx={{
              borderBottom:
                addType === "collection" ? `3px solid ${theme.text}` : "none",
              borderRadius: 0,
              width: "100%",
            }}
          >
            Collection
          </Button>
        )}
        <Button
          onClick={() => setAddType("exercise")}
          sx={{
            borderBottom:
              addType === "exercise" ? `3px solid ${theme.text}` : "none",
            borderRadius: 0,
            width: "100%",
          }}
        >
          Exercise
        </Button>
      </div>
      {addType === "exercise" && (
        <>
          <div style={{ margin: "0.5rem 0 1.3rem" }}>
            <Autocomplete
              noValidate
              freeSolo
              blurOnSelect
              id="input-name-box"
              options={selectList}
              sx={{ width: "100%" }}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Typography>
                    <b>{option.label}</b>
                    <span style={{ color: theme.textGray, fontSize: "0.8rem" }}>
                      , {option.exerciseEntries.length + " entries"}
                    </span>
                  </Typography>
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Exercise Name" />
              )}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
            />
          </div>
          <div
            style={{ margin: "0 0", minHeight: "10rem", textAlign: "center" }}
          >
            <AlreadyExistsInfo enabled={exerciseExists && !inCollection} />
            <AlreadyExistsWarn enabled={!isLoading && inCollection} />
            <SelectExerciseType enabled={!exerciseExists} />
          </div>
          <div style={{ margin: "0rem 0 0.5rem" }}>
            <LoadingButton
              loading={isLoading}
              disabled={inCollection || inputValue.length === 0}
              onClick={() => {
                setIsLoading(true);
                createExercise();
              }}
              variant="contained"
            >
              Add Exercise
            </LoadingButton>
          </div>
        </>
      )}
      {addType === "collection" && (
        <div style={{ margin: "2rem 0.5rem 1.5rem", textAlign: "left" }}>
          <TextField
            value={collectionName}
            onChange={(e) => {
              setCollectionName(e.target.value);
            }}
            fullWidth
            id="outlined-basic"
            label="Collection Name"
            variant="outlined"
          />
          <div style={{ marginTop: "4rem" }}>
            <LoadingButton
              loading={isLoading}
              disabled={collectionName === ""}
              onClick={() => {
                setIsLoading(true);
                addCollection(collectionName);
              }}
              variant="contained"
            >
              Add
            </LoadingButton>
          </div>
        </div>
      )}
    </div>
  );

  function AlreadyExistsWarn(props) {
    let enabled = !!props.enabled;
    if (enabled)
      return (
        <Card
          sx={{
            width: "100%",
            padding: "1.4rem 0",
            backgroundColor: theme.redBackground,
          }}
        >
          Exercise with same name already exists in this collection
        </Card>
      );
  }

  function SelectExerciseType(props) {
    let enabled = !!props.enabled;
    if (enabled)
      return (
        <div
          style={{
            margin: "0.5rem 0",
            textAlign: "left",
          }}
        >
          <Typography
            sx={{ fontSize: 15, marginBottom: "0rem", paddingBottom: "5px" }}
            gutterBottom
          >
            Exercise Type:
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <ExerciseTypeCard
              disabled={exerciseExists}
              onClick={() => setBodyWeight(false)}
              title={"Weight"}
              selected={!bodyWeight}
            />
            <ExerciseTypeCard
              disabled={exerciseExists}
              onClick={() => setBodyWeight(true)}
              title={"Body Weight"}
              selected={bodyWeight}
            />
          </div>
        </div>
      );
  }
  function AlreadyExistsInfo(props) {
    let enabled = !!props.enabled;
    if (enabled)
      return (
        <Card
          sx={{
            width: "100%",
            padding: "1.4rem 0",
            backgroundColor: theme.orangeBackground,
          }}
        >
          Will add existing exercise to collection
        </Card>
      );
  }

  function ExerciseTypeCard(props) {
    let title = props.title ? props.title : "";
    let selected = !!props.selected;
    let disabled = !!props.disabled;
    let onClick = props.onClick ? props.onClick : () => {};

    if (disabled) return;
    return (
      <Card
        onClick={onClick}
        sx={{
          width: "49%",
          padding: "1.4rem 0",
          border: selected ? "2px solid " + theme.graphStroke : "none",
          backgroundColor: theme.backgroundGrad2,
        }}
      >
        {title}
      </Card>
    );
  }

  function addCollection(name) {
    let collection = {
      name: name,
      exerciseIds: [],
    };
    rootCollection.collections.push(collection);
    updateCollection(rootCollection, () => {
      queryClient.invalidateQueries("exercises");
      queryClient.invalidateQueries("user");
      props.showOverlay(null, "");
    });
  }

  function createExercise() {
    if (!exerciseExists) {
      addExercise(
        { exerciseName: inputValue, bodyWeight: bodyWeight },
        (exerciseId) => addToCollection(exerciseId)
      );
    } else {
      addToCollection(nameToExerciseMap[inputValue].id);
    }
  }

  function addToCollection(exerciseId) {
    let collection = props.collection;
    let newId = { exerciseId: exerciseId, pos: 0 };
    collection.exerciseIds.push(newId);
    updateCollection(rootCollection, () => {
      queryClient.invalidateQueries("exercises");
      queryClient.invalidateQueries("user");
      props.showOverlay(null, "");
    });
  }
}
