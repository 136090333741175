import * as React from "react";
import { Button, Typography } from "@mui/material";
import { getExercises, uploadExercises } from "../apiRequests";
import { parseJwt } from "../../utils/crypto";

export default function More(props) {
  const { status, data, error, isFetching } = getExercises();

  if (status === "loading") {
    return "Loading";
  }

  if (status === "error") return "Error";

  console.log(parseJwt(props.token));

  return (
    <div
      style={{
        padding: "0 0.5rem",
        backgroundColor: "transparent",
        borderRadius: "10px",
        margin: "1rem",
      }}
    >
      <br />
      <Typography fontSize="1.6rem" component="div" color={"text.primary"}>
        General
      </Typography>
      <br />
      <p>Backup your exercise data by downloading it here</p>
      <Button
        sx={{
          borderRadius: "2px",
          margin: "0rem 0 2rem",
        }}
        variant="contained"
        onClick={() => {
          download("exercise_data.json", JSON.stringify(data.exercises));
        }}
      >
        Download exercise data
      </Button>

      <p>Upload exercise data from file here</p>
      <p>
        WARNING! exercises with same name will be merges with existing exercise
      </p>
      <Button
        sx={{
          borderRadius: "2px",
          margin: "0rem 0 2rem",
        }}
        variant="contained"
        onClick={() => {
          let input = document.createElement("input");
          input.type = "file";
          input.onchange = (e) => {
            // getting a hold of the file reference
            let file = e.target.files[0];

            // setting up the reader
            let reader = new FileReader();
            reader.readAsText(file, "UTF-8");

            // here we tell the reader what to do when it's done reading...
            reader.onload = (readerEvent) => {
              download(
                "backup_exercise_data.json",
                JSON.stringify(data.exercises)
              );

              let content = readerEvent.target.result; // this is the content!
              let list = JSON.parse(content);
              for (e of list) {
                e.userId = 0;
              }
              uploadExercises(list, () => console.log("uploaded exercises"));
              console.log(list);
            };
          };
          input.click();
        }}
      >
        Upload exercise data
      </Button>

      <br />
      <br />
      <br />
      <Typography fontSize="1.6rem" component="div" color={"text.primary"}>
        Account
      </Typography>
      <p>
        Currently logged in as: <br />
        <b>{parseJwt(props.token).sub}</b>
      </p>
      <Button
        sx={{
          borderRadius: "2px",
          margin: "0rem 0 2rem",
          backgroundColor: "gray",
        }}
        variant="contained"
        onClick={() => {
          localStorage.removeItem("jwt");
          props.setToken(null);
        }}
      >
        Logout
      </Button>
    </div>
  );
}

function download(filename, text) {
  let element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
