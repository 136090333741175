import { Button, Card, Typography } from "@mui/material";
import * as React from "react";
import AddEntryPage from "./AddEntryPage";
import AddIcon from "@mui/icons-material/Add";
import { getExercises } from "../apiRequests";
import { useQueryClient } from "react-query";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { getTheme } from "../../themes";
import ExerciseDetails from "./ExerciseDetails";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function ExerciseHeader(props) {
  const queryClient = useQueryClient();

  const { status, data, error, isFetching } = getExercises();
  if (status === "loading") {
    return "Loading";
  }
  if (status === "error") return "Error";

  let exercise = data.exerciseMap[props.exercise.id];
  if (!exercise) {
    return "Error: could not find exercise";
  }

  let theme = getTheme(exercise.category);
  if (props.favTheme != null) theme = props.favTheme;

  let prevWeight = " - ";
  let prevReps = " - ";
  let daysSince = "";
  let timeDiffHours = 999999999;

  let lastEntry;

  if (exercise.exerciseEntries.length > 0) {
    exercise.exerciseEntries = exercise.exerciseEntries.sort(
      (a, b) => b.sse - a.sse
    );

    lastEntry = exercise.exerciseEntries[0];
    prevWeight = lastEntry.weight;
    prevReps = lastEntry.reps;

    let now = new Date();
    let prevTime = new Date(exercise.exerciseEntries[0].sse * 1000);
    let diffInTime =
      new Date().getTime() / 1000 - exercise.exerciseEntries[0].sse;
    timeDiffHours = diffInTime / 3600;
    let diffInDays = Math.floor(diffInTime / (3600 * 24));

    if (now.toLocaleDateString() === prevTime.toLocaleDateString())
      daysSince = "Today: ";
    else if (
      new Date(now.getTime() - 1000 * 60 * 60 * 24).toLocaleDateString() ===
      prevTime.toLocaleDateString()
    )
      daysSince = "Yesterday: ";
    else if (diffInDays === 1) daysSince = "1 day ago: ";
    else daysSince = diffInDays + " days ago: ";
  }

  return (
    <Card
      sx={{
        textAlign: "left",
        margin: "0.25rem auto",
        width: "100%",
        boxShadow: 1,
        borderRadius: "13px",
        border: "2px solid " + theme.clickableBox,
      }}
    >
      <div
        style={{
          padding: "0.35rem 0.6rem",
          display: "flex",
          justifyContent: "space-between",
          gap: "2rem",
        }}
      >
        <div
          style={{
            cursor: "pointer",
            width: "100%",
          }}
          onClick={() => {
            if (props.inEditMode) {
            } else {
              props.nav.goTo(
                <ExerciseDetails
                  sx={{ width: "100%" }}
                  exercise={exercise}
                  graphTime={props.graphTime}
                />,
                <>
                  <FitnessCenterIcon
                    sx={{ marginBottom: "-2px" }}
                    fontSize="1rem"
                  />{" "}
                  {exercise.exerciseName}
                </>
              );
            }
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{}}>
              <div>
                <span
                  style={{
                    fontWeight: "550",
                    color: theme.textDark,
                    fontSize: props.withDetails ? "1.1rem" : "1rem",
                    lineHeight: "1rem",
                  }}
                  component="div"
                >
                  <>
                    <FitnessCenterIcon
                      sx={{ marginBottom: "-2px" }}
                      fontSize="1rem"
                    />{" "}
                  </>
                  {exercise.exerciseName}
                </span>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "0.55rem" }}>
            {!props.inEditMode && (
              <Typography
                lineHeight={1.2}
                fontSize={"0.85rem"}
                component="div"
                color={theme.textGray}
              >
                <span style={{ fontWeight: "550", color: theme.textGray }}>
                  {exercise.exerciseEntries.length === 0 ? "No entries" : ""}
                  {daysSince}
                </span>
                <EntryBox enabled={exercise.exerciseEntries.length} />
              </Typography>
            )}
            {props.inEditMode && (
              <span
                onClick={props.delete}
                style={{
                  color: theme.redText,
                  fontSize: "0.95rem",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Remove
              </span>
            )}
          </div>
        </div>
        <div>
          {!props.inEditMode && (
            <Button
              sx={{
                borderRadius: "20px",
                padding: "0",
                color: theme.text,
                marginTop: 0,
                marginRight: "-7px",
                maxHeight: "20px",
              }}
              variant="text"
              onClick={() => {
                props.showOverlay(
                  <AddEntryPage
                    lastEntry={lastEntry}
                    exercise={exercise}
                    showOverlay={props.showOverlay}
                    nav={props.nav}
                    inCollection={props.inCollection}
                  />,
                  "Add new entry"
                );
              }}
              endIcon={<AddIcon />}
            >
              Set
            </Button>
          )}
          {props.inEditMode && (
            <div
              style={{
                color: theme.text,
              }}
            >
              <div
                style={{
                  marginTop: "-8px",
                  marginBottom: "8px",
                  cursor: "pointer",
                }}
              >
                <KeyboardArrowUpIcon onClick={props.moveUp} />
              </div>
              <div
                style={{
                  cursor: "pointer",
                  marginBottom: "-8px",
                }}
              >
                <KeyboardArrowDownIcon onClick={props.moveDown} />
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );

  function EntryBox(props) {
    let enabled = !!props.enabled;

    if (enabled)
      return (
        <div
          style={{
            color: "#1f1f1f",
            display: "inline",
            padding: "0.13rem 0.3rem",
            borderRadius: "4px",
            backgroundColor: "#00000010",
            fontWeight: "bold",
            fontSize: "0.8rem",
          }}
        >
          {call(() => {
            if (exercise.exerciseEntries.length === 0) return "";
            else if (exercise.bodyWeight) return <span>{prevReps} reps</span>;
            else
              return (
                <span>
                  {prevWeight}kg, {prevReps} reps
                </span>
              );
          })}
        </div>
      );
  }
}

function call(fun) {
  return fun();
}
