import React, { useState } from "react";
import "./App.css";
import More from "./components/morePage/More";
import NavFrame from "./components/nav/NavFrame";
import MainPage from "./components/MainPage";
import { config } from "./components/apiRequests";
import LandingPage from "./components/LandingPage";
import NavBar from "./components/nav/NavBar";
import Overlay from "./components/nav/Overlay";

export let userId = 0;
let isMobile = false;

function App() {
  const [nav, setNav] = useState({
    stack: [],
    goTo: function (goToPage, title) {
      this.stack[this.stack.length - 1].scrollHeihgt = window.scrollY;
      this.stack.push({
        page: goToPage,
        title: title,
        haveRendered: false,
      });
      setNav(Object.assign({}, this));
    },
    goBack: function () {
      //setPageNum(0);
      this.stack.pop();
      setNav(Object.assign({}, this));
    },
    setRoot: function (root, title) {
      //setPageNum(0);
      this.stack = [{ page: root, title: title, haveRendered: false }];
      setNav(Object.assign({}, this));
    },
    clear: function () {
      //setPageNum(0);
      this.stack = [];
      setNav(Object.assign({}, this));
    },
    setAfterRoot: function (goToPage, title) {
      while (this.stack.length > 1) this.stack.pop();
      this.goTo(goToPage, title);
    },
    setHaveRendered: function () {
      this.stack[this.stack.length - 1].haveRendered = true;
    },
  });
  const [pageNum, setPageNum] = useState(0);
  const [showOverlay, setShowOverlay] = useState(null);
  const [token, setToken] = useState(null);

  if (!token) {
    let localToken = localStorage.getItem("jwt");
    if (localToken) setToken(localToken);
  }

  if (!token) {
    return (
      <LandingPage
        showOverlay={showOverlay}
        showOverlayWithTitle={showOverlayWithTitle}
        nav={nav}
        setToken={setToken}
      />
    );
  }

  config.headers.Authorization = "Bearer " + token;

  if (nav.stack.length === 0) {
    nav.setRoot(getPage(0));
  }

  return (
    <div
      className="App"
      style={{
        backgroundColor: "transparent",
        maxWidth: "600px",
        margin: "auto",
      }}
    >
      <div
        style={{
          marginTop: "60px",
        }}
      >
        <NavBar
          nav={nav}
          isMobile={isMobile}
          setPageNum={setPageNum}
          pageNum={pageNum}
          getPage={getPage}
        />
        <NavFrame nav={nav} />
      </div>

      {showOverlay ? showOverlay : ""}
    </div>
  );

  function getPage(selectedPage = 0) {
    if (selectedPage === 0) {
      return <MainPage nav={nav} showOverlay={showOverlayWithTitle}></MainPage>;
    } else if (selectedPage === 1) {
      return <More token={token} setToken={setToken} />;
    } else {
      return <MainPage nav={nav} showOverlay={showOverlayWithTitle}></MainPage>;
    }
  }

  function showOverlayWithTitle(content, title) {
    if (!content) {
      setShowOverlay(null);
      document.documentElement.style.overflow = "auto"; // firefox, chrome
      document.body.scroll = "yes"; // ie only
    } else {
      setShowOverlay(
        <Overlay
          content={content}
          title={title}
          showOverlayWithTitle={showOverlayWithTitle}
        />
      );
      document.documentElement.style.overflow = "hidden"; // firefox, chrome
      document.body.scroll = "no"; // ie only
    }
  }
}

export default App;
